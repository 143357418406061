.primary, .secondary{
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 12px 25px !important;
    gap: 8px !important;
    width: auto !important;
    min-width: 125px !important;
    height: 48px !important;
    border-radius: 4px !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    text-align: center !important;
    letter-spacing: -0.011em !important;
    border: none;
    cursor: pointer;
    transition: all 0.4s ease;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    & > a{
        color: inherit;
        font-size: inherit;
    }
}
.primary{
    background: #19943C !important;
    color: #FFFFFF !important;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12) !important;
    & > svg{
        & > path{
            fill: #fff;
        }
    }
}
.primary:hover{
    background-color: #016E20 !important;
}
.primary:active, .secondary:active{
    transform: scale(0.9) !important;
}
// seconary button
.secondary{
    background-color: transparent !important;
    color: #19943C !important;
}
.secondary:hover{
    background: #EDFAF1 !important;
}

@media screen and (max-width: 1024px) {
    .primary, .secondary{
        font-size: 14px !important;
        height: 40px !important;
        min-width: 100px !important;
    }
  }