.container{
    position: relative;
    display: grid;
    grid-template-columns: 525px repeat(3, 1fr);
    grid-template-rows: 1fr 37px;
    column-gap: 24px;
    width: 93%;
    height: auto;
    min-height: 426px;
    margin: 0 auto;
    padding-top: 48px;
    padding-bottom: 10px;
}
.productSection, .integrationSection{
    & > h3{
        box-shadow: 0px 0.8px 0px rgba(10, 10, 10, 0.2);
        padding: 0px 0px 8px;
        width: fit-content;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
    }
    & > ul{
        gap: 10px;
        & > li{
            margin: 5px 0;
            height: auto;
            min-height: 24px;
            & > a{
                text-align: left !important;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                letter-spacing: -0.011em;
                color: #424242;
            }
        }
    }
}
.productSection{
    grid-column: 2 / 3;
}
.copyRight{
    width: 100%;
    grid-column: 2 / -1;
    grid-row: 2 / -1;
    display: flex;
    & > ul{
        display: flex;
        align-items: center;
        margin-right: 20px;
        & > li{
            margin-right: 32px;
            & > a{
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                text-align: center;
                color: #0A0A0A;
            }
        }
    }
    & > div{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 315px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.006em;
        word-wrap: break-word;
        word-break: keep-all;
        & > span{
            display: inline-block;
            white-space: nowrap;
            align-items: center;
            & > svg{
                position: relative;
                top: 2px;
                margin: 0 5px;
            }
        }
    }
}
.sqaures{
    position: absolute;
    width: 427px;
    height: 97px;
    left: 40px;
    bottom: 0;
    background: #19943C;
    &::before{
        content: '';
        position: absolute;
        width: 98px;
        height: 98px;
        left: -23%;
        bottom: 100%;
        background: #19943C;
    }
}
@media screen and (max-width: 900px) {
    .container{
        z-index: 99;
        width: 85% !important;
        display: flex;
        flex-direction: column;
        & > div{
            margin-bottom: 26px;
        }
        & > .copyRight{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 0;
            & > ul{
                width: 100%;
                gap: 0; 
                justify-content: center;
                margin-right: auto !important;
                margin-left: auto !important;
                & > li{
                    display: flex;
                    justify-content: center;
                    margin-right: 6px;
                    margin-left: 6px;
                }
            }
        }
    }
    .reserved{
        display: flex;
        z-index: 999;
        text-align: center;
        align-items: center !important;
        margin-top: 26px;
        white-space: normal !important;
        inline-size: 150px; 
    }
}

@media screen and (max-width: 1200px){
    .reserved{
        & > span{
            white-space: normal !important;

        }
    }
    .sqaures{
        width: 60px;
        height: 60px;
        left: -0%;
        bottom: -20px;
        margin: 0 !important;
        &::before{
            content: '';
            position: absolute;
            width: 60px;
            height: 60px;
            left: -60px;
            bottom: 100%;
        }
    }
}