.container{
    width: 100%;
    &  img:first-child{
        width: 492px !important;
        height: 542px !important;
        margin: 0 auto;
    }
    & img:nth-child(2){
        width: 218px;
        height: 170px;
        left: 420px;
        top: 70px;
    }
    & img:nth-child(3){
        left: 1%;
        bottom: 30%;
    }
}
.featureWrapper{
    margin: 48px auto auto !important;
    width: 70%;
}
.feature{
    margin-bottom: 28px !important;
    & > div{
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        & > svg{
            margin-right: 20px;
        }
        & > h3{
            margin: 0;
        }
    }
}

@media screen and (max-width: 900px) {
    .container{
        &  img:first-child{
            margin-top: -80px;
        }
    }
    .featureWrapper{
        margin-top: 8px !important;
    }
    .feature{
        & > div{
            margin-left: 5%;
            & > svg{
                margin-right: 20px;
            }
            & > h3{
                margin: 0;
            }
        }
        & > p{
            width: 90% !important;
            margin-left: 5%;
        }
    }
}