.container{
    width: 93%;
    max-width: 1440px !important;
    margin: 48px auto auto !important;
    height: auto;
    min-height: 320px;
    display: flex;
    align-items: center;
}
.ctaTitle{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    & > h1{
        vertical-align: middle !important;
        font-weight: 800;
        font-size: 80px;
        line-height: 80px;
    }

}
.content{
    display: flex !important;
    flex-direction: column;
    justify-content: center;
}
.btnWrapper{
    margin-top: 16px;
    display: flex;
}

@media screen and (max-width: 900px) {
    .container{
        width: 90% !important;
        margin-left: auto;
        margin-right: auto;
    }
    .ctaTitle{
        & > h1{
            padding-left: 0 !important;
            margin: 0 !important;
            padding-right: 0 !important;
            font-size: 42px;
            line-height: 53px;
        }
    }    
}