.container{
    width: 100%;
    margin: 120px auto auto !important;
}
.heroWrapper{
    margin: 80px auto auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 665px;
    text-align: center;
    & > p{
        margin-bottom: 40px;
    }
}
.ctaWrapper{
    width: 100%;
    height: auto !important;
    min-height: 892px;
    margin-top: 60px !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    background: #19943C;
    clip-path: polygon(0 0, 100% 15%, 100% 100%, 0 85%);
    align-items: center !important;
    padding: 0 32px !important;
    & h2{
        color: #fff;
        font-weight: 800;
        line-height: 64px;
        letter-spacing: -0.022em;
        margin-bottom: 32px;
    }
    p{
        color: #fff;
    }
    p:not(:last-child){
        margin-bottom: 40px !important;
    }
}
.imgWrapper{
    & > img{
        &:first-child{
            width: 664px;
            height: 444px;
        }
        &:nth-child(2){
            position: absolute;
            width: 218px;
            height: 170px;
            left: 172px;
            top: -28px;
        }
        &:last-child{
            position: absolute;
            width: 218px;
            height: 170px;
            right: 5%;
            top: 260px;
            border-radius: 12px;
        }
    }
}

@media screen and (max-width: 900px) {
    .heroWrapper{
        width: 80%;
    }
    .ctaWrapper{
        width: 100%;
        clip-path: polygon(0 0, 100% 3%, 100% 100%, 0 98%);
        padding: 4rem 5% !important;
    }
    .imgWrapper{
        & > img{
            &:first-child{
                object-fit: contain;
            }
            &:nth-child(2){
                width: 142px;
                height: 120px;
                left: 172px;
                top: 38px;
            }
            &:last-child{
                width: 142px;
                height: 120px;
                left: 255px;
                top: 260px;
            }
        }
    }
    .ctaWrapper{
        & h2{
            line-height: 48px;
        }
    }
}