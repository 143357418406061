.container{
    max-width: 1440px;
    display: flex;
    position: fixed;
    width: 100%;
    padding: 0 48px;
    margin: 0 auto;
    height: auto;
    min-height: 72px;
    justify-content: space-between;
    align-items: center;
    z-index: 9999;
    transition: all 0.4s ease;
    opacity: 1;
    background-color: transparent;
    & > div{
        display: flex;
        align-items: center;
        & li{
            display: flex;
            place-items: center;
            width: fit-content;
            padding: 8px;
            border-radius: 6px;
            cursor: pointer;
           & > a{
            letter-spacing: -0.006em;
            font-style: normal;
            font-weight: 500;
            text-align: center;
            font-size: 15px;
            line-height: 20px;
            color: inherit;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
           }
           &:hover{
                background: #EDFAF1;
                color: #016E20;
           }
           &.active{
                background: #EDFAF1;
                color: #0C4A1E;
           }
        }
        &:first-child{
            & > nav{
                display: flex;
                align-items: center;
                column-gap: 32px;
                margin-left: 32px;
            }
        }
        &:last-child{
            & > li{
                margin-right: 32px;
                & > a{
                    color: #19943C;
                }
            }
        }
    }
}
.logoWrapper{
    margin-top: 10px;
    cursor: pointer;
}
@media screen and (min-width:900px) {
    .userMenuMobile, .mobileMenuContainer{
        display: none !important;
    }
    nav{
        opacity: 1 !important;
    }
}
@media screen and (max-width:900px) {
    a{
        font-weight: 400;
    }
    .container{
        padding: 0 28px;
        & > div{
            width: 100%;
            display: flex;
            justify-content: space-between;
            & > div{
                position: relative;
                z-index: 999;
            }
        }
    }
    nav{
        position: absolute;
        flex-direction: column !important;
        align-items: flex-end !important;
        justify-content: flex-end;
        height: 22rem;
        width: 11rem;
        padding-right: 24px;
        padding-bottom: 24px;
        z-index: 99;
        top: 20px;
        right: 0;
        background-color: #fff;
        border-radius: 10px 0 0 10px;
        box-shadow: 0px 8px 20px 20px rgba(237, 250, 241, 0.1);
    }
    .userMenu{
        display: none !important;
    }
    .userMenuMobile{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        & > button:first-child{
            padding:  0 5px !important;
            margin-top: 10px;
        }
    }
    .mobileMenuContainer{
        z-index: 9999;
        background: rgba(25, 148, 60, 0.1);
        border-radius: 50%;
        padding: 4px;
        max-width: 40px !important;
        max-height: 40px !important;
        overflow: hidden;
    }
    .showMenu{
        // transform: translateX(100%);
    }
}

@media screen and (max-width: 1024px) {
    
}