.heroWrapper{
    width: 100%;
    & img:nth-child(2){
        left: 25%;
        top: 5%;
    }
    & img:nth-child(3){
        left: 68%;
        bottom: 35%;
    }
}