.container{
    position: relative;
    width: 93%;
    max-width: 1440px !important;
    margin: 120px auto auto !important;
    height: auto !important;
    min-height: 672px;
}
.heroImgContainer{

}
.dashboardImgWrapper{
    height: 100% !important;
    width: 100% !important;
    overflow: hidden;
    display: flex !important;
    align-items: center ;
    justify-content: flex-end ;
    margin-top: -18px !important;
    & > img.dashboard{
        filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12));
        box-shadow: 0px 3px 13px 2px rgba(103, 103, 103, 0.18);
        border-radius: 12px;
        width: 664px;
        height: 396px;
    }
    & > img.summary{
        position: absolute;
        left: 252px;
        top: 20px;
        width: 218px;
        height: 170px;
        object-fit: cover;
    }
    & > img.creditCard{
        position: absolute;
        width: 218px;
        height: 170px;
        left: 526px;
        bottom: 60px;
        object-fit: cover;
    }
    &::before{
        content: "";
        position: absolute;
        width: 398px;
        height: 398px;
        left: calc(50% - 398px/2 - 26px);
        top: calc(50% - 398px/2 + 61.5px);

        /* Primary/Main */

        background: #19943C;
        filter: blur(480px);
    }
}
.content{
    width: 100%;
    max-width: 480px !important;
    & > h1{
        width: 551px;
    }
    & > p{
        color: #424242;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 40px;
    }
    & > .btnWrapper{
        display: flex;
    }
}
@media screen and (max-width:900px) {
    .container{
        height: auto !important;
        width: 90% !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .dashboardImgWrapper{ 
        margin-left: 5%;
        margin-top: 26px !important;
        display: flex !important;
        justify-content: center;
        & > img.dashboard{
            object-fit: contain;
            box-shadow: none !important;
        }
        & > img.summary{
            left: 252px;
            top: 85px !important;
            width: 168px;
            height: 120px;
        }
        & > img.creditCard{
            width: 148px;
            height: 100px;
            left: 160px !important;
            bottom: 80px !important;
        }
    }
    .content{
        width: 90% !important;
        margin-left: auto !important;
        margin-right: auto !important;
        & > h1{
            font-size: 42px;
            width: 100%;
            line-height: 53px;
        }
        & > p{
            hyphens: auto;
            width: 100%;
            text-align: justify;
            text-justify: distribute;
        }
    }
    .logoWrapper{
        width: 90%;
        row-gap: 30px;
        & > div{
            width: 100px;
            height: 20px;
        }
    }
}