.container{
    width: 93%;
    margin: 80px auto auto;
}
.heroWrapper{
    margin: 0 !important;
    height: 580px;
    width: 100%;
    align-items: center !important;
}
.imgWrapper{
    position: relative;
    width: 100% !important;
    height: 100%;
    padding: 0 !important;
    margin-right: auto !important;
    & > img:first-child{
        width: 492px !important;
        height: 484px !important;
        margin-right: auto !important;
        filter: drop-shadow(2px 2px 11px rgba(10, 10, 10, 0.22));
    }
    &::before{
        content: '';
        position: absolute;
        width: 326px;
        height: 326px;
        left: calc(50% - 326px/2 + 1.5px);
        top: calc(50% - 326px/2 + 51px);
        background: #19943C;
        filter: blur(480px);
    }
    & > img:last-child{
        position: absolute;
        width: 218px;
        height: 170px;
        right: 12%;
        top: 266px;
        filter: drop-shadow(2px 2px 11px rgba(10, 10, 10, 0.22));
    }
}
.content{
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    max-width: 532px;
    & > p{
        margin-bottom: 40px;
    }
    & > button{
        max-width: 150px;
    }
}
.main{
    display: grid;
    grid-template-columns: 136px 1fr;
    column-gap: 202px;
    margin-top: 96px;
}