.container{
    width: 93%;
    height: auto !important;
    margin: 120px auto auto;
    display: grid;
    grid-template-columns: 390px repeat(auto-fit, minmax(320px, 1fr));
}
.header{
    margin-top: 80px;
    width: 374px;
    margin-right: 50px !important;
    & > h1{
        font-weight: 800;
        font-size: 64px;
        line-height: 70px !important;
        letter-spacing: -0.022em;
    }
}
.trustedLogos{
    margin-top: 170px;
    & > span{
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
        color: #616161;
    }
}
.logoWrapper{
    width: 395px;
    display: flex;
    grid-gap: 24px;
    margin-top: 20px;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 40px;
    & > div{
        width: auto;
        width: 112px;
        height: 24px;
        display: flex;
        align-items: center;
        cursor: pointer;
        & > img{
            width: 100%;
            object-fit: scale-down;
            filter: hue-rotate(286deg);
            opacity: 0.6;
            &:hover{
            opacity: 1
            }
        }
    }
}
.platform, .custom{
    width: 110%;
    margin-top: 10.5rem;
    height: 843px;
    background: #19943C;
    box-shadow: 0px 3px 13px rgba(103, 103, 103, 0.18);
    border-radius: 60px 10px 60px 10px;
    transform: skewY(33deg);
    padding: 0 40px;
    & > .content{
        transform: skewY(-33deg);
        margin-top: 60px;
        & > button{
            margin-top: 48px !important;
        }
        h2{
            color: #fff;
            font-weight: 500;
            font-size: 88px;
            line-height: 96px;
            letter-spacing: -0.006em;
        }
        & > h3{
            width: auto !important;
            color: #fff;
            max-width: 362px;

            &.desc{
                font-size: 20px;
                line-height: 32px;
                letter-spacing: -0.006em;
                font-weight: 500;
                margin-bottom: 48px;
            }
        }
        & > span{
            color: #fff;
        }
    }
}
.platform{
    margin-left: 5%;
    z-index: 2;
}
.custom{
    z-index: 1;
    margin-left: 10%;
    background: #EDFAF1;
    box-shadow: none;
    padding-left: 64px;
    & h2, h3{
        color: #424242 !important;
    }
    & > .content{
        margin-top: 40px;
        & > .title{
            width: 256px;
            font-weight: 500;
            font-size: 20px;
            line-height: 32px;
            letter-spacing: -0.006em;
        }
        & > .desc{
            font-style: normal;
            font-weight: 400 !important;
            font-size: 18px !important;
            line-height: 24px;
            letter-spacing: -0.006em;
            width: 338px !important;
        }
        & > .list{
            & > div{
                & > .iconWrapper{
                    background: rgba(10, 10, 10, 0.1);
                    & > svg{
                        & > path{
                            fill: #424242;
                        }
                    }
                }
            }
        }
    }
}
.list{
    & > div{
        margin-bottom: 32px;
        display: flex;
        align-items: center;
        & > .iconWrapper{
            margin-right: 10px;
            width: 40px;
            height: 40px;
            background: rgba(255, 255, 255, 0.1);
            padding: 8px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            & > svg{
                & > path{
                    fill: #fff;
                }
            }
        }
        & > h3{
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.006em;
            color: #FFFFFF;
            margin: 0;
        }
    }
}

// list price features

.featureWrapper{
    grid-column: 1 / -1;
    margin: 240px auto auto !important;
    width: 90%;
    & > .featureWrapperRow{
        column-gap: 60px !important;
    }
}
.questionsWrapper{
    grid-column: 1 / -1;
    margin: 144px auto auto !important;
    width: 90%;
    & h3{
        font-size: 20px;
    }
}
.feature{
    margin-bottom: 28px !important;
    & > div{
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        & > svg{
            margin-right: 20px;
        }
        & > h3{

            margin: 0;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
        }
    }
}

@media screen and (max-width: 1024px) {
    .custom{
        grid-column: 2 / -1;
    }
}
@media screen and (max-width: 900px) {
    .container{
        width: 90%;
        margin-top: 60px;
        margin-left: auto !important;
        margin-right: auto !important;
        display: flex;
        flex-direction: column;
    }
    .header{
        width: 90% !important;
        margin-left: auto;
        margin-right: auto;
    }
    .platform, .custom{
        width: 90% !important;
        padding: 0 30px;
        margin: 0 auto;
        transform: skewY(25deg);
        & > .content{
            transform: skewY(-25deg);
            & > .list{
                & > div{
                    & > .iconWrapper{
                        width: 30px;
                        height: 30px;
                        & > svg{
                        }
                    }
                }
            }
        }
    }
    .platform{
        margin-top: 15rem;
    }
    .custom{
        margin-top: 2rem;
    }
    h2{
        width: 100%;
    }
    h3{
        line-height: 80px;
        font-size: 20px;
    }
    .list{
        width: 90%;
    }
    .questionsWrapper{
        margin-top: 60px !important;
    }
    .trustedLogos{
        margin-top: 60px;
        & > div{
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
        }
    }
    .featureWrapperRow{
        width: 90%;
    }
}