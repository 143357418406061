.container{
    width: 100%;
    & img:nth-child(2){
        left: 65%;
        top: 5%;
    }
    & img:nth-child(3){
        left: 1%;
        bottom: 15%;
    }
}
.howItWorks{
    width: 110%;
    height: auto;
    min-height: 1186px;
    // margin-left: 20px !important;
    background: #19943C;
    clip-path: polygon(0 0, 100% 15%, 100% 100%, 0 85%);
}
.content{
    margin-top: 98px !important;
    margin-left: 48px !important;
    & > h2, p{
        color: #fff;
    }
}
.imgWrapper{
    position: relative;
    margin-top: 144px;
    width: 626px;
    height: 420px;
    & > img:first-child{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    & > img:nth-child(2){
        position: absolute;
        width: 202px;
        height: 170px;
        left: 90px;
        top: -40px;
    }
    & > img:last-child{
        position: absolute;
        width: 202px;
        height: 170px;
        left: 350px;
        top: 320px;
    }
}
.listWrapper{
    margin-top: 240px !important;
    & > div{
        display: flex;
        column-gap: 30px;
        margin-bottom: 120px;
        & h1{
            transform: scale(1, 1.2);
            font-size: 200px;
            margin: auto 0;
            color: transparent;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: #fff;
        }
        & > div{
            width: 420px;
            & > h3{
                color: #fff;
            }
            & > p{
                color: #fff;
                text-justify: distribute;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .container{
        width: 100%;
        & img:nth-child(2){
            left: 10%;
            top: 5%;
        }
    }
    .content{
        & > h2{
            margin-top: 80px;
        }
    }
    .howItWorks{
        padding: 5% !important;
        width: 100%;
        clip-path: polygon(0 0, 100% 3%, 100% 100%, 0 98%);
    }
    .imgWrapper{
        margin-top: 0px;
        width: 100%;
        & > img:nth-child(2){
            position: absolute;
            width: 152px;
            height: 120px;
            left: 90px;
            top: 40px;
        }
        & > img:last-child{
            position: absolute;
            width: 132px;
            height: 100px;
            left: 150px;
            top: 220px;
        }
    }
    .listWrapper{
        margin-top: 8px !important;
        & > div{
            align-items: center;
            & > div{
                width: 100% !important;
            }
        }
    }
}