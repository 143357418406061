.container{
    position: relative;
    height: 200px;
    & > li{
        height: 40px;
        display: flex;
        align-items: center;
        position: relative;
        height: 40px;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.011em;
        color: #0A0A0A;
        margin-bottom: 40px;
        padding-left: 12px;
        cursor: pointer;
        &.active{
            color: #19943C;
            &::before{
                content: '';
                position: absolute;
                width: 4px;
                height: 40px;
                left: 1px;
                top: 0px;
                background: #19943C;
                border-radius: 1px;
            }
        }
    }
    &::before{
        content: '';
        position: absolute;
        width: 0px;
        height: 100%;
        left: 2px;
        top: 0px;
        border: 1px solid #9E9E9E;
    }
}