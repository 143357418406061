.container{
    max-width: 666px;
    margin-bottom: 46px;
    & > div{
        margin-top: 64px;
    }
    & > h2{
        margin-top: -20px;
    }
    & > h3{
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.017em;
        color: #0A0A0A;
        margin-top: 16px;
       
    }
    & > p{
        margin: 24px 0;
    }
}