@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;500;600;700&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  width: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  margin: 0;
  padding: 0;
  font-family: "Kumbh Sans", sans-serif !important;
  transition: all 0.4s ease;
  color: #0a0a0a;
}
*:hover {
  transition: all 0.4s ease;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
li {
  list-style: none;
}
a {
  position: relative;
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  text-align: center;
  letter-spacing: -0.011em;
  color: #19943c;
  border-radius: 6px;
  & > svg{
    margin: 7px 0 auto 12px;
  }
}
a:hover {
  color: #0c4a1e;
}
h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 76px;
  letter-spacing: -0.022em;
  color: #19943c;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin-bottom: 32px;
}
h2 {
  font-style: normal;
  font-weight: 800;
  font-size: 52px;
  line-height: 76px;
  letter-spacing: -0.022em;
  color: #19943c;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin-bottom: 8px;
}
h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: -0.011em;
  color: #424242;
}
p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #424242;
}
/* helper styles */
.no-padding {
  padding: 0 !important;
}
.no-padding-left {
  padding-left: 0 !important;
}

.show-upper-bg{
  &::before{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 784px;
      height: 792px;
      background-size: contain;
      background-image: url("./assets/bg-top-left.svg");
      z-index: -1;
  }
}

@media screen and (max-width:900px) {
  h1{
      font-size: 42px;
      line-height: 48px !important;
  }
  h2{
      font-size: 36px;
      line-height: 132%;
  }
  h3 {
    font-size: 18px !important; 
  }
  p {
    font-size: 16px;
  }
}
@media screen and (max-width: 900px) {
  .pc-only{
    display: none;
  }
}
@media screen and (min-width: 900px) {
  .mobile-only{
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  a{
      font-size: 14px !important;
  }
}
