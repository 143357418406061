.container{
    position: relative;
    width: 93%;
    margin: 140px auto auto !important;
    height: auto;
    min-height: 672px;
}
.dashboardImgWrapper{
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: -18px;
    & > img.dashboard{
        filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12)) !important;
        box-shadow: 0px 3px 13px 2px rgba(103, 103, 103, 0.18);

        border-radius: 12px;
        width: 664px;
        height: 396px;
    }
    & > img.summary{
        position: absolute;
        left: 252px;
        top: 20px;
        width: 218px;
        height: 170px;
        object-fit: cover;
    }
    & > img.creditCard{
        position: absolute;
        width: 218px;
        height: 170px;
        left: 65%;
        bottom: 60px;
        object-fit: cover;
    }
    &::before{
        content: "";
        position: absolute;
        width: 398px;
        height: 398px;
        left: calc(50% - 398px/2 - 26px);
        top: calc(50% - 398px/2 + 61.5px);
        background: #19943C;
        filter: blur(480px);
    }
}
.content{
    width: 100%;
    & > p.subHeader{
        color: #424242;
        font-weight: 500;
        font-size: 20px;
        margin: 0;
    }
    & > div{
        margin-top: 64px;
        & > p {
            margin-bottom: 32px !important;
            line-height: 24px;
            letter-spacing: -0.011em;
        }
    }
}

@media screen and (max-width: 900px) {
    .container{
        width: 90% !important;
        margin-left: auto;
        margin-right: auto;
        & > div{
            flex-direction: column !important;
        }
    }
    .dashboardImgWrapper{
        & > img.dashboard{
            object-fit: contain;
        }
        & > img.summary{
            position: absolute;
            left: 52px;
            top: -40px;
        }
        & > img.creditCard{
            left: 166px;
            bottom: 20px;
            width: 200px;
            height: 140px;
            object-fit: contain;
        }
    }
    .content{
        width: 90%;
        h2{
            font-size: 36px;
        }
        & > p.subHeader{
            color: #424242;
            font-weight: 500;
            font-size: 20px;
            margin: 0;
        }
        & > div{
            & > p {
                margin-bottom: 32px !important;
                line-height: 24px;
                letter-spacing: -0.011em;
            }
        }
    }

}