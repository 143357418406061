.container{
    width: 93%;
    height: auto;
    min-height: 918px !important;
    margin: 60px auto auto !important;
}
.sliderContainer{
    position: relative;
    display: flex !important;
    flex-direction: column;
    row-gap: 80px;
    &::before{
        content: "";
        position: absolute;
        width: 4px;
        height: 822px;
        background: #E0E0E0;
    }
    & > div{
        position: relative;
        padding-left: 24px !important;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        cursor: pointer;
        &.activeSlide{
            &::before{
                content: "";
                position: absolute;
                top: 0;
                left: 0px;
                width: 4px;
                height: 112px;
                background: #19943C;
            }
        }
        & > .iconWrapper{
            width: 64px ;
            height: 64px ;
            padding: 16px;
            display: grid;
            place-items: center;
            background: rgba(25, 148, 60, 0.1);
            border-radius: 50%;
        }
        & > h3{
            margin: 8px 0;
        }
        & > p{
            max-width: 551px;
        }
    }
}
.sliderImgWrapper{
    height: auto;
    width: 100%;
    & > img{
        width: 100% !important;
        margin-left: -5px;
    }
}

.trustedLogos{
    margin-top: 100px;
    & > span{
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
        color: #616161;
    }
}
.logoWrapper{
    width: 395px;
    display: flex;
    grid-gap: 24px;
    margin-top: 20px;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 40px;
    & > div{
        width: auto;
        width: 112px;
        height: 24px;
        display: flex;
        align-items: center;
        cursor: pointer;
        & > img{
            width: 100%;
            object-fit: scale-down;
            filter: hue-rotate(286deg);
            opacity: 0.6;
            &:hover{
            opacity: 1
            }
        }
    }
}

@media screen and (max-width:900px) {
    .container{
        margin-top: 5% !important;
        width: 90% !important;
    }
    .sliderContainer{
        &::before{
            display: none;
        }
        & > div{
            padding: 0 !important;
        }
    }
    .sliderImgWrapper{
        display: none !important;
    }
    .activeSlide{
        &::before{
            display: none !important;    
        }
    }
    .iconWrapper{
        padding: 0;
        width: 50px !important;
        height: 50px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        & svg{
            width: 20px;
            height: 20px;
        }
    }
}