.container{
    width: 93%;
    margin: 120px auto auto !important;
    height: 677px !important;
    display: flex;
    column-gap: 90px;
}
.heroImgWrapper{
    position: relative;
    width: 100%;
    display: flex !important;
    justify-content: center;
    align-items: flex-end;
    padding-right: 80px !important;
    &::before{
        content: "";
        position: absolute;
        background-color: #19943C;
        width: 98px;
        height: 98px;
        bottom: 14.5%;
        left: -8%;
    }
    &::after{
        content: "";
        position: absolute;
        background-color: #19943C;
        width: 428px;
        height: 98px;
        bottom: 0%;
        left: 44px;
    }
    & > img{
        margin-top: auto !important;
        height: 100%;
        width: 100%;
        object-fit: contain;
        object-position: bottom;
    }
}
.btnWrapper{
    display: flex;
    margin-top: 40px;
}
.heroContent{
    width: 100%;
    margin-right: auto;
    margin-top: 65px;
    & > p{
        max-width: 430px;
        hyphens: auto;
        text-align: justify;
    }
}
.trustedLogos{
    margin-top: 49px;
    & > span{
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
        color: #616161;
    }
}
.logoWrapper{
    width: 395px;
    display: flex;
    grid-gap: 24px;
    margin-top: 20px;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 40px;
    & > div{
        width: auto;
        width: 112px;
        height: 24px;
        display: flex;
        align-items: center;
        cursor: pointer;
        & > img{
            width: 100%;
            object-fit: scale-down;
            filter: hue-rotate(286deg);
            opacity: 0.6;
            &:hover{
            opacity: 1
            }
        }
    }
}

@media screen and (max-width:900px) {
    .container{
        height: auto !important;
        & > div{         
            display: flex !important;
            flex-direction: column !important;
        }
    }
    .heroImgWrapper{
        min-width: 100% !important;
        padding: 0 !important;
        margin: 0;
        display: flex !important;
        justify-content: center;
        &::before{
            width: 60px;
            height: 60px;
            bottom: 55px;
            left: -30px;
        }
        &::after{
            height: 55px;
            left: 30px;
            bottom: 0px;
            width: 60%;
        }
        & > img{
            min-width: 100%;
            object-fit: contain;
            height: 500px;
        }
    }
    .heroContent{
        min-width: 90% !important;
        margin-left: auto;
        margin-right: auto;
        margin-top: 35px !important;
        & > h1{
            font-size: 42px;
            width: 80%;
            line-height: 53px;
        }
        & > p{
            hyphens: auto;
            width: 80%;
            text-align: justify;
            text-justify: distribute;
        }
    }
    .logoWrapper{
        width: 90%;
        row-gap: 30px;
        & > div{
            width: 100px;
            height: 20px;
        }
    }
}

@media screen and (max-width: 480px) {
    .container{
        margin-top: 0 !important;
    }
    .heroImgWrapper > img{
        max-height: 480px !important;
    }
}