.container{
    position: relative;
    max-width: 1440px !important;
    margin: 0 auto !important;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    &::after{
        content: "";
        z-index: -1;
        position: absolute;
        left: 0;
        bottom: 0%;
        width: 887px;
        height: 896px;
        background-size: contain;
        background-image: url("../assets/bg-top-left.svg");
    }
}
