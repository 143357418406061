.container{
    width: 93%;
    height: auto;
    min-height: 1160px !important;
    margin: 160px auto auto !important;
}
.sliderContainer{
    position: relative;
    display: flex !important;
    flex-direction: column;
    row-gap: 48px;
    & > div{
        position: relative;
        display: flex;
        justify-content: flex-start;
        column-gap: 16px;
        cursor: pointer;
        & > .iconWrapper{
            width: 40px;
            height: 40px;
            padding: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(25, 148, 60, 0.1);
            border-radius: 50%;
        }
        & > .content{
            max-width: 495px;
            & > h3{
                margin-bottom: 8px;
            }
            & > a{
                margin-top: 28px;
            }
        }
    }
}
.sliderImgWrapper{
    position: relative;
    height: auto;
    width: 100%;
    & > img:first-child{
        width: 626px;
        height: 507px;
        margin-left: auto;
    }
    & > img:nth-child(2){
        position: absolute;
        width: 218px;
        height: 170px;
        left: -40px;
        top: 300px;
        border-radius: 12px;
    }
    & > img:nth-child(3){
        position: absolute;
        width: 218px;
        height: 170px;
        left: 455px;
        top: -60px;
        border-radius: 12px;

    }
}

.trustedLogos{
    margin-top: 100px;
    & > span{
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
        color: #616161;
    }
}
.logoWrapper{
    width: 395px;
    display: flex;
    grid-gap: 24px;
    margin-top: 20px;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 40px;
    & > div{
        width: auto;
        width: 112px;
        height: 24px;
        display: flex;
        align-items: center;
        cursor: pointer;
        & > img{
            width: 100%;
            object-fit: scale-down;
            filter: hue-rotate(286deg);
            opacity: 0.6;
            &:hover{
            opacity: 1
            }
        }
    }
}
@media screen and (max-width:900px) {
    .container{
        margin-top: 80px !important;
        width: 90% !important;
    }
    .sliderContainer{
        & > div{
            flex-direction: column;
        }
    }
    .sliderImgWrapper{
        display: flex !important;
        justify-content: center;
        & > img:first-child{
            object-fit: contain;
            box-shadow: none !important;
        }
        & > img:nth-child(2){
            left: 52px;
            top: 85px !important;
            width: 168px;
            height: 120px;
        }
        & > img:last-child{
            object-fit: contain;
            width: 148px;
            height: 100px;
            left: 100px !important;
            top: 280px !important;
        }
    }
    .activeSlide{
        &::before{
            display: none !important;    
        }
    }
    .iconWrapper{
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}