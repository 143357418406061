.container{
    width: 93%;
    margin: 48px auto auto !important;
    height: auto;
    min-height: 621px;
}
.exploreCardWrapper{
    margin-top: 40px;
}
.exploreCard{
    box-shadow: none !important;
    width: 100% !important;
    height: auto;
    min-height: 408px;
    border: 1px solid #B6DBC1 !important;
    border-radius: 12px !important;
    overflow: hidden;
    & > .exploreCardContent{
        padding: 24px;
        & > .exploreCardIcon {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px;
            width: 64px;
            height: 64px;
            background: rgba(25, 148, 60, 0.1);
            border-radius: 360px;
            cursor: pointer;
        }
        & > h3{
            font-size: 24px !important;
        }
        & > p{
            min-height: 160px;
        }
    }
    & > .exploreCardFooter{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 16px 24px !important;
        gap: 10px;
        width: 100%;
        height: 56px;
        background: #EDFAF1 !important;
        box-shadow: 0px -1px 0px #B6DBC1 !important;
        & > a{
            font-style: normal;
            font-weight: 600;
            font-size: 18px !important;
            line-height: 24px;
            letter-spacing: -0.011em;
            color: #19943C !important;
            margin-right: 14px !important;
        }
    }
}

@media screen and (max-width: 900px) {
    .container{
        & .extra{
            height: 420rem !important;
        }
        h2{
            width: 90% !important;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .exploreCardWrapper{
        padding: 0 2% !important; 
        margin-top: 24px !important;
        & > div{
            padding-right: 0 !important;   
            padding-left: 0 !important;
        }
    }
}